import { useMemo } from "react"
import classnames from "classnames"
import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

import { DoctorTimetableCalendar } from "components/DoctorTimetable/Calendar"

/**
 * タブでカレンダを切り替える
 * edges には {node: {...}} の配列
 *
 */
export const DoctorTimetable = ({
  edges,
  months, // ex ["2022-04", "2022-05"]
}) => {
  const thisYearDisplay = months[0].split("-")[0]
  const nextYearDisplay = months[1].split("-")[0]
  const thisMonthDisplay = Number(months[0].split("-")[1])
  const nextMonthDisplay = Number(months[1].split("-")[1])

  const { locale } = useLocalization()
  const { t } = useTranslation()

  const THIS_MONTH_PATH = `${
    locale.slice(0, 2) === "en" ? "/en" : ""
  }/appointment_menu/doctor_timetable/`
  const NEXT_MONTH_PATH = `${
    locale.slice(0, 2) === "en" ? "/en" : ""
  }/appointment_menu/doctor_timetable/next_month/`

  const path = typeof window !== `undefined` ? window.location.pathname : ""

  const tableTitle = useMemo(() => {
    return path === THIS_MONTH_PATH
      ? t(
          "{{year}} 年 {{month}} 月外来診療予定表",

          {
            year: thisYearDisplay,
            month: Number(thisMonthDisplay),
          },
        )
      : t(
          "{{year}} 年 {{month}} 月外来診療予定表",

          {
            year: nextYearDisplay,
            month: Number(nextMonthDisplay),
          },
        )
  }, [
    path,
    THIS_MONTH_PATH,
    thisYearDisplay,
    thisMonthDisplay,
    nextYearDisplay,
    nextMonthDisplay,
  ])

  const monthMap = {
    1: t("1月", "1月"),
    2: t("2月", "2月"),
    3: t("3月", "3月"),
    4: t("4月", "4月"),
    5: t("5月", "5月"),
    6: t("6月", "6月"),
    7: t("7月", "7月"),
    8: t("8月", "8月"),
    9: t("9月", "9月"),
    10: t("10月", "10月"),
    11: t("11月", "11月"),
    12: t("12月", "12月"),
  }

  return (
    <div className="main_area inviewfadeInUp">
      <div className="general_tab">
        <nav className="general_select">
          <ul className="nav nav-tabs">
            <li>
              <Link to={THIS_MONTH_PATH}>
                <span
                  className={classnames({
                    ["active"]: path === THIS_MONTH_PATH,
                  })}
                >
                  {t("{{month}} 月外来診療予定", {
                    month: monthMap[thisMonthDisplay],
                  })}
                </span>
              </Link>
            </li>
            <li>
              <Link to={NEXT_MONTH_PATH}>
                <span
                  className={classnames({
                    ["active"]: path === NEXT_MONTH_PATH,
                  })}
                >
                  {t("{{month}} 月外来診療予定", {
                    month: monthMap[nextMonthDisplay],
                  })}
                </span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="tab-content">
        <div className="tab-pane active" id="month_01">
          <div className="general_box">
            <div className="general_box inviewfadeInUp">
              <div className="general_part">
                <h3>{tableTitle}</h3>
                <DoctorTimetableCalendar edges={edges} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
