import { getDay } from "date-fns"
import { useTranslation } from "react-i18next"
import { v4 } from "uuid"

export const CLOSED = "休"
const timetableLabel = ["○", "△"]

/**
 *
 * Contentful で入稿された am, pm の文字列を整形する
 *
 * - am が休みならば全休として「休」を skyblue class の span
 * - comma を <br /> に
 * - ○△ を skyblue class の span
 * に変換する
 */
export const addStyle = content => {
  const { t } = useTranslation()

  if (content === CLOSED)
    return <span className="skyblue">{t("休", "休")}</span>
  // "," で doctor を分割
  return content?.split(",").map(word => (
    <>
      {word
        .split("") // １文字ごとに分割して timetableLabel に該当するなら skyblue にする
        .map(char =>
          timetableLabel.includes(char) ? (
            <span key={v4()} className="skyblue">
              {char}
            </span>
          ) : (
            char
          ),
        )}
      <br />
    </>
  ))
}

/**
 * 曜日分の 7 この node の配列をもつ２次元配列
 *
 * 火曜日始まりの場合、ひとつ空 node がある
 * [
 *   [{ node: {} }, { node: { データあり } }, ..x6],
 *   [{ node: { データあり } }, ....x7  ],
 *   [{ node: { データあり } }, ....x7  ],
 *   [{ node: { データあり } }, ....月まで  ],
 * ]
 */
export const makeWeekNodes = edges => {
  const firstday = new Date(edges[0].node.date)
  const dayofweek = getDay(firstday)
  // 日曜日は 7 として扱う
  const insertspace = dayofweek === 0 ? 6 : dayofweek - 1

  // 空の node の配列を作成して先頭に挿入する
  const emptyarray = [...new Array(insertspace)].map(m => ({ node: {} }))

  const weekNodes = [...emptyarray, ...edges].reduce(
    (mem, prev) => {
      if (mem[mem.length - 1].length < 7) {
        mem[mem.length - 1].push(prev)
      } else {
        mem.push([prev])
      }
      return mem
    },
    [[]],
  )
  return weekNodes
}
