import { getDay } from "date-fns"
import { v4 } from "uuid"
import { useTranslation } from "react-i18next"

import { CLOSED, addStyle } from "funcs/helpers/doctorTimetable"

/**
 * モバイル用カレンダー
 */
const DoctorCell = ({ node }) => {
  const { t } = useTranslation()

  const dayofweekMap = [
    t("(日)", "(日)"),
    t("(月)", "(月)"),
    t("(火)", "(火)"),
    t("(水)", "(水)"),
    t("(木)", "(木)"),
    t("(金)", "(金)"),
    t("(土)", "(土)"),
  ]
  const dayofweekJa = dayofweekMap[getDay(new Date(node.date))]

  return (
    <tr>
      <th>
        {node.day}日<br />
        {dayofweekJa}
      </th>
      {node.am === CLOSED ? (
        <td colSpan="2">
          <span className="skyblue">{CLOSED}</span>
        </td>
      ) : (
        <>
          <td key={v4()}>{addStyle(node.am)}</td>
          <td key={v4()}>{addStyle(node.pm)}</td>
        </>
      )}
    </tr>
  )
}

export const MoMonthTable = ({ data }) => {
  const { t } = useTranslation()

  return (
    <div className="mo">
      <table className="month">
        <tbody>
          <tr>
            <th></th>
            <th className="ampm">{t("午前", "午前")}</th>
            <th className="ampm">{t("午後", "午後")}</th>
          </tr>
          {data.map(edge => (
            <DoctorCell key={edge.node.id} node={edge.node} />
          ))}
        </tbody>
      </table>
    </div>
  )
}
