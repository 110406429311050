import { MoMonthTable } from "./MonthTable/Mo"
import { PcMonthTable } from "./MonthTable/Pc"
import { MonthTableNote } from "./MonthTableNote"

/**
 * カレンダー本体
 *
 * Pc と Mo
 */
export const DoctorTimetableCalendar = ({ edges }) => {
  return (
    <div className="month">
      <div className="schedule_area">
        <PcMonthTable data={edges} />

        <MoMonthTable data={edges} />
        <MonthTableNote />
      </div>
    </div>
  )
}
