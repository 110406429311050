import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

/**
 * 外来受診のご案内 のナビゲーション
 */
export const AppointmentNavi = () => {
  const { t } = useTranslation()

  return (
    <nav className="side_nav">
      <div className="sticky">
        <div>
          <h2>{t("外来受診のご案内", "外来受診のご案内")}</h2>
          <ul>
            <li>
              <Link to="/appointment_menu/about_appointment">
                {t("ご予約について", "ご予約について")}
              </Link>
            </li>
            <li>
              <Link to="/appointment_menu/doctor_s_schedule">
                {t("医師ごとの診療時間", "医師ごとの診療時間")}
              </Link>
              <ul className="small_nav">
                <li>
                  <Link to="/appointment_menu/doctor_s_schedule">
                    {t("古谷公一医師", "古谷公一医師")}
                  </Link>
                </li>
                <li>
                  <Link to="/appointment_menu/doctor_s_schedule_02">
                    {t("山本秀雄 医師", "山本秀雄 医師")}
                  </Link>
                </li>
                <li>
                  <Link to="/appointment_menu/doctor_s_schedule_03">
                    {t("松山明子 医師", "松山明子 医師")}
                  </Link>
                </li>
                <li>
                  <Link to="/appointment_menu/doctor_s_schedule_04">
                    {t("加藤貴保子 医師", "加藤貴保子 医師")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/appointment_menu/doctor_timetable">
                {t("月間診療予定", "月間診療予定")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
