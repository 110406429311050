import { useTranslation } from "react-i18next"

export const MonthTableNote = () => {
  const { t } = useTranslation()

  return (
    <>
      <p className="at_t ">
        <span className="skyblue">○</span>：
        {t(
          "古谷医師の診察は手術終了後からとなります",
          "古谷医師の診察は手術終了後からとなります",
        )}
      </p>
      <p className="at_t ">
        <span className="skyblue">△</span>：
        {t(
          "松山医師・加藤医師の診察は17時までとなっております。",
          "松山医師・加藤医師の診察は17時までとなっております。",
        )}
      </p>
      <p className="at_t ">
        <span className="red">※</span>：
        {t(
          "木曜日の診療は19時までとなっております。",
          "水曜日の診療は19時までとなっております。",
        )}
      </p>
      <p className="at_t ">
        <span className="red">※</span>：
        {t(
          "急に予定変更する場合がございます。",
          "急に予定変更する場合がございます。",
        )}
      </p>
      <p className="at_t ">
        <span className="red">{t("※", "")}</span>
        {t(
          "：Dr Kouichi Furuya=古谷 Dr Hideo Yamamoto＝山本 Dr Akiko Matsuyama＝松山 Dr Kihoko Kato＝加藤",
          "",
        )}
      </p>
    </>
  )
}
