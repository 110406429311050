import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"

import Footer from "components/Footer"
import { PageTopAnchor } from "components/PageTopAnchor"
import { PcFooterLink } from "components/FooterLink/Pc"
import { MoFooterLink } from "components/FooterLink/Mo"
import { MoFooterWrap } from "components/FooterWrap"
import { PhoneArea } from "components/PhoneArea"
import { BannerArea } from "components/BannerArea"
import { DoctorTimetable } from "components/DoctorTimetable"
import { AppointmentNavi } from "components/AppointmentNavi"

/**
 * /appointment_menu/doctor_timetable ページを生成するテンプレート
 * pageContext
 *   node: 当月分のデータ en のページであっても中身は ja のままとする
 *   months: 設定された当月翌月が YYYY-MM 形式の string[]
 */
const DoctorTimetableByYearMonthtTemplate = ({ pageContext }) => {
  const { months, node: data } = pageContext
  const { t } = useTranslation()

  const localizedData = data.filter(
    ({ node }) => node.node_locale.slice(0, 2) === "ja",
  )

  return (
    <main>
      <Helmet title={t("月間診療予定", "月間診療予定")} />
      <div className="sub_page">
        <section className="page_header service inviewfadeInUp">
          <div className="container2">
            <h1>
              <span>{t("外来受診のご案内")}</span>
              {t("月間診療予定", "月間診療予定")}
            </h1>
          </div>
        </section>

        <div className="container3">
          <section className="breadcrumb">
            <ul>
              <li>
                <Link to="/">{t("星井眼科医院TOP", "星井眼科医院TOP")}</Link>
              </li>
              <li>
                <Link to="/appointment_menu">
                  {t("外来受診のご案内", "外来受診のご案内")}
                </Link>
              </li>
              <li>{t("月間診療予定", "月間診療予定")}</li>
            </ul>
          </section>

          <section className="content">
            <DoctorTimetable edges={localizedData} months={months} />
            <AppointmentNavi />
          </section>
        </div>
      </div>
      <div className="container2">
        <section className="content">
          <PhoneArea />
          <BannerArea />
        </section>
      </div>
      <section className="content">
        <div className="footer_link inviewfadeInUp">
          <PcFooterLink />
          <MoFooterLink />
        </div>
      </section>

      <Footer />
      <PageTopAnchor />
      <MoFooterWrap />
    </main>
  )
}

export default DoctorTimetableByYearMonthtTemplate
